/* TitleText */
.title-text {
  opacity: 0;
  position: relative;
  animation: titleText 0.75s forwards;
}
@keyframes titleText {
  0% {
    bottom: -2rem;
    opacity: 1;
  }
  50% {
    bottom: 0.2rem;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
/*-------------Hero----------*/
.hero-text {
  font-size: 3em;
  background-color: rgba(0, 0, 0, 0.4);
}
/*------------Home Component-----------*/
.home-main-container {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
  background-color: black;
}
.title-cont {
  display: flex;
  justify-content: center;
}
.title {
  font-size: 2.3em;
}
.welcome-content {
  display: flex;
  justify-content: center;
}
.welcome {
  font-size: 1.4em;
}
.services-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
}
.services-home a {
  flex-basis: 20%;
}
.text-above-img {
  text-align: center;
  font-size: 1.4em;
}
.home-img {
  width: 250px;
  margin: 0 20px;
}
/*-----------About---------------*/
.about-container {
  margin: 0 20px;
  background: rgba(0, 0, 0, 0.7);
}
.about-description {
  font-size: 1.3em;
  padding: 15px;
}
.about-list {
  font-size: 1.3em;
  padding: 10px;
}
/*----------Footer---------------*/
footer {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #9d9d9d;
  text-align: center;
  padding: 10px 0;
}

/*----------mobile/tablet friendly-------*/
@media screen and (max-width: 800px) {
  .top-items h4 {
    flex-basis: 100%;
  }
  .email-sent {
    display: none;
  }
  .home-main-container {
    width: 98%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .copyright {
    font-size: 1em;
  }
  .spacer {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .copyright {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 350px) {
  .home-main-container {
    width: 100%;
  }
  .copyright {
    font-size: 0.6em;
  }
}
