@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:wght@400;700&family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
body {
  background-color: #e8e8e8;
}
.extra-SEO {
  display: none;
}
hr {
  width: 30%;
  margin: 0 auto;
  border-top: 10px double rebeccapurple;
}
a {
  text-decoration: none;
  color: red;
  font-weight: bolder;
  transition: all 0.3s;
}
a:hover {
  color: blue;
}
li {
  list-style-type: none;
}
.line {
  width: 60%;
  margin: 10vh auto 5vh auto;
  border-bottom: 4px solid #010e14;
}
/* Sections */
.homeHeroImg {
  background: linear-gradient(to bottom, rgba(92, 84, 69, 0.6), rgba(92, 84, 69, 0.6)), url(./assets/homeHero.jpg);
  background-size: cover;
  background-position: bottom;
}

/* Fonts */
.text-primary {
  font-family: 'Arvo', serif;
}
.text-secondary {
  font-family: 'Raleway', sans-serif;
}
.text-subtitle {
  font-family: 'Roboto', sans-serif;
}
.imgCaption {
  font-size: 0.8rem;
  color: #4b4b4b;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
