.gallery-nav-container {
  margin: 12vh 0;
  position: sticky;
  top: 10vh;
}
.gallery-nav {
  display: flex;
  justify-content: space-evenly;
}
.gallery-list {
  font-size: 1.4rem;
  cursor: pointer;
  color: #eeee;
  padding: 10px;
  border-radius: 15px;
  background-color: #3d3d3d;
}
.gallery-img-container2 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 5vh 0;
  gap: 30px;
}
.gallery-title2 {
  flex-basis: 100%;
  font-size: 2.5rem;
  text-align: center;
  text-decoration: underline;
}
.gallery-img2 {
  width: 300px;
  border-radius: 10px;
}
.gallery-img2-caption {
  text-align: center;
  font-size: 1.1rem;
}
.gallery-img-big {
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 450px) {
  .gallery-list {
    font-size: 0.9rem;
  }
}
